import React from "react";
import { IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import "./Home.css";
import { arrowForward } from "ionicons/icons";
import { SplittedContent } from "../components/SplittedContent";
import { CloudsAnimation } from "../components/CloudsAnimation";
import { BrandTitle } from "../components/BrandTitle";
import { BrandPane } from "../components/BrandPane";
const Tab1: React.FC = () => {
  return (
    <IonPage className="ambient-day">
      <CloudsAnimation></CloudsAnimation>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton title="FIND">
          <IonIcon icon={arrowForward} />
        </IonFabButton>
      </IonFab>
      <SplittedContent
        leftPane={<BrandPane></BrandPane>}
        rightPane={
          <div className="main-pane">
            <div className="header-pane">
              <BrandTitle text="Explore and charge"></BrandTitle>
            </div>

            <img
              src="assets/img/man-charge.png"
              alt="charge"
              style={{ width: "auto" }}
            />
          </div>
        }
      ></SplittedContent>
    </IonPage>
  );
};
export default Tab1;
