import { createGlobalState } from "react-hooks-global-state";
import { MapFiltersModel } from "./MapFiltersModel";

export type AppContextModel={
  filters:  MapFiltersModel

}
 
 const {useGlobalState, getGlobalState} =  createGlobalState<AppContextModel>({
  filters: {
    available:false,
    connectors:[],
    powers:[],
    scoring:[],
    services:[]
  }

 }) ;
 export const getAppContext=getGlobalState;
 export const useAppContext=useGlobalState;