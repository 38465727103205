import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonContent, IonBackButton, IonButtons } from '@ionic/react'
import { RouteComponentProps } from 'react-router';
import { SearchBar } from '../components/SearchBar';

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
}> {}

const PickAddressPage: React.FC<UserDetailPageProps> = ({match, history}) => {
  return (
    <IonPage>
     <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
        <IonBackButton text="Cancel"/>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
      <IonContent>
      <SearchBar></SearchBar>
     
      </IonContent>
    </IonPage>
  );
};

export default PickAddressPage;