import {
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonList,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonToggle,
} from "@ionic/react";
import { batteryCharging, cart, checkmark, link, star } from "ionicons/icons";
import React, { useState } from "react";
import { useAppContext } from "../context/appContext";

import "./SearchBar.css";
export const SearchBar = () => {
  const [filterContext, setFilterContext] = useAppContext("filters");
  const [service] = useState("Trip");
  const [power, setPower] = useState<string[]>([]);
  const [connectors, setConnectors] = useState<string[]>([]);
  const [available, setAvailable] = useState(filterContext.available);
  const [scoring, setScoring] = useState<string[]>([]);
  const [services, setServices] = useState<string[]>([]);


  const applyFilters = function () {
    setFilterContext({
      available: available,
      connectors: [...connectors],
      powers: [...power],
      scoring: [...scoring],
      services: [...services]
    });
  };
  return (
    <div className="search-bar">
      <IonList>
        <IonItem>
          <IonSegment
            onIonChange={(e) => console.log("Segment selected", e.detail.value)}
          >
            <IonSegmentButton value={service}>
              <IonLabel>EXPLORE</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="Disposition">
              <IonLabel>TRIP</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h2>Only available</h2>
          </IonLabel>
          <IonIcon icon={checkmark} slot="start" />
          <IonToggle
            value={"available"}
            checked={available}
            onIonChange={(e) => setAvailable(e.detail.checked)}
          />
        </IonItem>
        <IonItem>
          <IonLabel>
            <h2>Power (Kwh)</h2>
          </IonLabel>
          <IonIcon icon={batteryCharging} slot="start" />
          <IonSelect
            multiple={true}
            value={power}
            placeholder="Power"
            onIonChange={(e) => setPower(e.detail.value)}
          >
            <IonSelectOption value="power_3">3 kW</IonSelectOption>
            <IonSelectOption value="power_7">7 kW</IonSelectOption>
            <IonSelectOption value="power_18">18 kW</IonSelectOption>
            <IonSelectOption value="power_43">43 kW</IonSelectOption>
            <IonSelectOption value="power_100">100 kW</IonSelectOption>
            <IonSelectOption value="power_350">350 kW</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem>
          <IonLabel>
            <h2>Connectors</h2>
          </IonLabel>
          <IonIcon icon={link} slot="start" />
          <IonSelect
            multiple={true}
            value={connectors}
            placeholder="Connectors"
            onIonChange={(e) => setConnectors(e.detail.value)}
          >
            <IonSelectOption value="connector_dom_ue">
              Domestique UE
            </IonSelectOption>
            <IonSelectOption value="connector_3c">Type 3C</IonSelectOption>
            <IonSelectOption value="connector_2c">Type 2C</IonSelectOption>
            <IonSelectOption value="connector_1c">Type 1C</IonSelectOption>
            <IonSelectOption value="connector_chademo">CHAdeMO</IonSelectOption>
          </IonSelect>
        </IonItem>
        
        <IonItem>
          <IonLabel>
            <h2>Scoring</h2>
          </IonLabel>
          <IonIcon icon={star} slot="start" />
          <IonSelect
            multiple={true}
            value={scoring}
            placeholder="Scoring"
            onIonChange={(e) => setScoring(e.detail.value)}
          >
            <IonSelectOption value="scoring_5">5 stars</IonSelectOption>
            <IonSelectOption value="scoring_4">4 stars</IonSelectOption>
            <IonSelectOption value="scoring_3">3 stars</IonSelectOption>
            <IonSelectOption value="scoring_2">2 stars</IonSelectOption>
            <IonSelectOption value="scoring_1">1 star</IonSelectOption>
           </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h2>Services</h2>
          </IonLabel>
          <IonIcon icon={cart} slot="start" />
          <IonSelect
            multiple={true}
            value={services}
            placeholder="Services"
            onIonChange={(e) => setServices(e.detail.value)}
          >
            <IonSelectOption value="service_gas_station">Gas station</IonSelectOption>
            <IonSelectOption value="service_payment">Payment</IonSelectOption>
            <IonSelectOption value="service_snack">Snack</IonSelectOption>
            <IonSelectOption value="service_restaurant">Restaurant</IonSelectOption>
            <IonSelectOption value="service_shop">Shop</IonSelectOption>
            <IonSelectOption value="service_cash">Cash</IonSelectOption>
           </IonSelect>
        </IonItem>
      </IonList>
   
      <IonButton
        onClick={applyFilters}
        routerLink="/clusters"
        fill="outline"
        title="Search"
        size="default"
        style={{ width: "100%" }}
      >
        APPLY
      </IonButton>
    </div>
  );
};
