import React from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./ClusterView.css";
import { MapContainer } from "../components/Map/MapContainer";
import { SearchBar } from "../components/SearchBar";

import {
  personCircle,
  optionsOutline,
} from "ionicons/icons";

const ClusterView: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
            <IonButton>
        <IonIcon src="assets/img/logo.svg"></IonIcon>
        </IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton>
              <IonIcon slot="icon-only" icon={personCircle} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton color="secondary">
            </IonButton>
          </IonButtons>
          <IonTitle>Cluster view</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow style={{ height: "100%" }}>
          <IonCol sizeLg="4" size="12" className="ion-hide-lg-down">
            <SearchBar></SearchBar>
          </IonCol>
          <IonCol sizeLg="8" size="12">
            <MapContainer></MapContainer>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton title="Search" routerLink="/search">
          <IonIcon icon={optionsOutline} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default ClusterView;
