import { IonIcon } from "@ionic/react";
import React from "react";

export const MapMarker = () => (
  <IonIcon
    icon="ios-pin"
    style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 99999,
      color: "var(--f7-theme-color)",
    }}
  ></IonIcon>
);
