import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { appsOutline, home, listOutline } from "ionicons/icons";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/overrides.css";
import PickAddressModal from "./pages/PickAddressModal";
import SearchModal from "./pages/SearchModal";
import ClusterView from "./pages/ClusterView";
import ListView from "./pages/ListView";

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/search" component={SearchModal} exact={true} />
            <Route
              path="/pickaddress"
              component={PickAddressModal}
              exact={true}
            />
            <Route path="/home" component={Home} exact={true} />
            <Route path="/clusters" component={ClusterView} exact={true} />
            <Route path="/list" component={ListView} />
            <Route
              path="/"
              render={() => <Redirect to="/clusters" />}
              exact={true}
            />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={home} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>

            <IonTabButton tab="Cluster places around" href="/clusters">
              <IonIcon icon={appsOutline} />
              <IonLabel>Cluster view</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Places around" href="/list">
              <IonIcon icon={listOutline} />
              <IonLabel>List view</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
