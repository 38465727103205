import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonContent, IonBackButton, IonButtons } from '@ionic/react'
import { RouteComponentProps } from 'react-router';
import { ImgList } from '../components/ImgList';

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
}> {}

const PickAddressModal: React.FC<UserDetailPageProps> = ({match, history}) => {
  return (
    <IonPage>
     <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
        <IonBackButton text="Cancel"/>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
      <IonContent>     
        User {match.params.id}
        <ImgList></ImgList>
      </IonContent>
    </IonPage>
  );
};

export default PickAddressModal;