import { IonImg, IonTitle, IonText, IonRow, IonCol } from "@ionic/react";
import React from "react";
import { ENV } from "../env/env";
import "./BrandPane.css";
export const BrandPane = () => (
  <div className="brand-pane">
    <div className="brand-pane-header">
      <IonImg
        src={"assets/img/bemo-logo-color.svg"}
        style={{ height: 50 }}
      ></IonImg>
      <IonRow>
        <IonCol style={{ textAlign: "center", marginTop: "20px" }}>
          <IonTitle>Be:Mo Places explorer</IonTitle>
          <IonText>{ENV.CURRENT_ENV === "prod" ? "" : ENV.CURRENT_ENV}</IonText>
        </IonCol>
      </IonRow>
    </div>
  </div>
);
