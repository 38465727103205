import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import "./SplittedContent.css";
export const SplittedContent = (props: {
  className?: string;
  leftPane: JSX.Element;
  rightPane: JSX.Element;
}) => (
  <IonRow className={`app-split-layout parra-main ${props.className}`}>
    <IonCol className="app-split-layout-left parra-header"  sizeLg="4" size="12" >
      {props.leftPane}
    </IonCol>
    <IonCol
      className="app-split-layout-right parra-content "
      sizeLg="8"
      size="12"
    >
      {props.rightPane}
    </IonCol>
  </IonRow>
);
