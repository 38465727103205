import { IonTitle } from "@ionic/react";
import React from "react";

export const BrandTitle = (props: { text: string }) => (
  <IonTitle
    size="large"
    style={{
      color: "white",
      textAlign: "center",
      textShadow: "1px 1px 2px black",
    }}
  >
    <h1>{props.text}</h1>
  </IonTitle>
);
