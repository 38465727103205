import React from "react";
import "./CloudsAnimation.css";

export const CloudsAnimation = () => (
  <div id="Clouds">
    <div className="Cloud Foreground"></div>
    <div className="Cloud Background"></div>
    <div className="Cloud Foreground"></div>
    <div className="Cloud Background"></div>
    <div className="Cloud Foreground"></div>
    <div className="Cloud Background"></div>
    <div className="Cloud Background"></div>
    <div className="Cloud Foreground"></div>
    <div className="Cloud Background"></div>
    <div className="Cloud Background"></div>
  </div>
);
