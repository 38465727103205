/// <reference path="../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />

import React, { useEffect, useRef, useState } from "react";
import { loadBingApi } from "./mapWindow";
import { MapMarker } from "./MapMarker";
import "./MapContainer.css";
import { getAppContext, useAppContext } from "../../context/appContext";
import { ENV } from "../../env/env";
interface IMapProps {
  mapOptions?: any;
}

function createIcon(radius: number, fillColor: string, outlineWidth: number) {
  //Create an SVG string of two circles, one on top of the other, with the specified radius and color.
  return [
    '<svg xmlns="http://www.w3.org/2000/svg" width="',
    radius * 2,
    '" height="',
    radius * 2,
    '">',
    '<circle cx="',
    radius,
    '" cy="',
    radius,
    '" r="',
    radius,
    '" fill="',
    fillColor,
    '"/>',
    '<circle cx="',
    radius,
    '" cy="',
    radius,
    '" r="',
    radius - outlineWidth,
    '" fill="',
    fillColor,
    '"/>',
    "</svg>",
  ];
}
export const MapContainer = (props: IMapProps) => {
  const mapRef = useRef<any>();
  const mapInstanceRef = useRef<Microsoft.Maps.Map>();
  const [loaded, setLoaded] = useState(false);
  const [filterContext] = useAppContext("filters");

  //const apiHost = "http://localhost:7241";

  const apiHost = ENV.API_HOST;
  const buildSubFilter = (subFilter: string[]) => subFilter.join("|");

  const refreshMap = async (
    map: any,
    firstLat: number,
    firstLng: number,
    lastLat: number,
    lastLng: number,
    level: number
  ) => {
    //adap map zoom with geohash precision
    const geoHashLevel =
      level <= 3
        ? 2
        : level <= 5
        ? 2
        : level <= 7
        ? 3
        : level <= 10
        ? 4
        : level <= 13
        ? 5
        : level >= 14
        ? 6
        : 3;

    const query = `${apiHost}/api/GetClustersArround?firstBound=${firstLat},${firstLng}&lastBound=${lastLat},${lastLng}&level=${geoHashLevel}&filters=${
      getAppContext("filters").available ? "available" : ""
    },${buildSubFilter(getAppContext("filters").powers)},${buildSubFilter(
      getAppContext("filters").connectors
    )},${buildSubFilter(getAppContext("filters").scoring)},${buildSubFilter(
      getAppContext("filters").services
    )}`;

    await fetch(query).then((response) => {
      response.json().then((geoJsonResult: Microsoft.Maps.IGeoJsonObject) => {
        var pins = geoJsonResult.features?.map((feature) => {
          if (feature.geometry?.coordinates) {
            const clusterSize = feature.properties.placeCount;
            const radius = (Math.log(clusterSize) / Math.log(10)) * 5 + 10;

            //Make the cluster yellow if there are 10 to 99 pushpins in it.
            const fillColor =
              feature.properties.placeCount === 1
                ? feature.properties.counters.available
                  ? "rgba(11, 156, 49, 0.8)"
                  : "red"
                : "rgba(0, 0, 10, 0.5)";

            const pin = new Microsoft.Maps.Pushpin(
              new Microsoft.Maps.Location(
                feature.geometry?.coordinates[1] ?? 0,
                feature.geometry?.coordinates[0] ?? 0
              ),
              {
                icon: createIcon(radius, fillColor, 10).join(""),

                textOffset: { x: 0, y: radius / 1.5 } as Microsoft.Maps.Point,
                text: `${
                  feature.properties.placeCount === 1
                    ? ""
                    : feature.properties.placeCount
                }`,
              }
            );
            // if (feature.properties.level === 12) {
            //   Microsoft.Maps.Events.addHandler(pin, "click", function () {
            //     console.log("pushpinClick");
            //   });
            // }

            pin.metadata = {
              title: feature.properties?.name,
            };

            return pin;
          }
        });

        map?.entities?.clear();
        map?.entities?.push(pins);
      });
    });
  };
  useEffect(() => {
    if (mapInstanceRef.current && loaded) {
      refresh(mapInstanceRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterContext]);

  useEffect(() => {
    async function load() {
      await loadBingApi(
        "AnC9_QwzKMA-eIu5GnZQ9fp8vLo0EXWOdD43xN9GQ9OJ5gARftzfG3qHdBySyB9-"
      );
      if (mapRef.current) {
        const map = new Microsoft.Maps.Map(mapRef.current, {});
        mapInstanceRef.current = map;
        if (props.mapOptions) {
          map.setOptions(props.mapOptions);
        }
        //Add view change events to the map.

        Microsoft.Maps.Events.addHandler(map, "viewchangeend", function () {
          refresh(map);
        });

        setTimeout(function () {
          // const firstCorner = map?.getBounds().getNorthwest();
          // const lastCorner = map?.getBounds().getSoutheast();
          // const level = map?.getZoom();
          // if (firstCorner && lastCorner) {
          //   refreshMap(
          //     map,
          //     firstCorner.latitude,
          //     firstCorner.longitude,
          //     lastCorner.latitude,
          //     lastCorner.longitude,
          //     level ?? 1
          //   );
          // }
          setLoaded(true);
        }, 1000);
      }
    }

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);
  async function refresh(map: Microsoft.Maps.Map) {
    if (map === undefined) {
      return;
    }
    const bounds = map.getBounds();
    const firstCorner = bounds.getNorthwest();
    const lastCorner = bounds.getSoutheast();
    const level = map.getZoom();

    await refreshMap(
      map,
      firstCorner.latitude,
      firstCorner.longitude,
      lastCorner.latitude,
      lastCorner.longitude,
      level
    );
  }

  return (
    <div ref={mapRef} className="map-container">
      <MapMarker></MapMarker>
    </div>
  );
};
