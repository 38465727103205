import React from "react";
import {
  IonList,
  IonItem,
  IonThumbnail,
  IonImg,
  IonLabel,
  IonContent,
} from "@ionic/react";

type Item = {
  src: string;
  text: string;
};
const items: Item[] = [
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
  { src: "http://placekitten.com/g/200/300", text: "a picture of a cat" },
];

export const ImgList: React.FC = () => (
  <IonContent>
    <IonList>
      {items.map((image, i) => (
        <IonItem key={i}>
          <IonThumbnail slot="start">
            <IonImg src={image.src} />
          </IonThumbnail>
          <IonLabel>{image.text}</IonLabel>
        </IonItem>
      ))}
    </IonList>
  </IonContent>
);
